<template>
  <v-container responsive>
    <v-row>
      <v-col>
         
        <v-dialog
          v-model="dialog"
          
        >
          <template v-slot:activator="{ props }">
            <v-btn
              color="primary"   
              v-bind="props"
            >
              Create Project
            </v-btn>
          </template>

          <v-card
          class="pa-5 ma-auto"
          width="50%"
          >
            <new-project-form
                @closeDialog="dialog=false"
            />
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row>
        <v-col>
          <user-project-list-table />
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
    data(){
        return {
            dialog: false,
        }
    },
    methods:{
        closeDialog(){
            this.dialog = false;
        }
    },
computed:{
  myProjects(){
      return this.$store.getters['projectData/myProjects'];
    }
  }
}
</script>

<style>

</style>