<template>
    <v-form @keyup.enter="attemptRegistration">
        <v-row>
            <v-col cols="12">
                <v-text-field
                v-model="email"
                filled
                label="Your Email Address"
                clearable
                type="email"
                :rules="[
                    v => !!v || 'Email is required',
                    v => /.+@.+/.test(v) || 'Invalid Email address' 
                ]"
            ></v-text-field>

            <v-text-field
                v-model="password"
                filled
                label="Password"
                clearable
                type='password'
                :rules="[
                    v => !!v || 'Password Is Required',
                    v => v.length >= 6 || 'Password must be at least 6 characters long'
                ]"
            ></v-text-field>

            <v-text-field
                v-model="password_confirmation"
                filled
                label="Password"
                clearable
                type='password'
                :rules="[
                    v => !!v || 'Password Confirmation Is Required',
                    v => v.length >= 6 || 'Password Confirmation must be at least 6 characters long'
                ]"
            ></v-text-field>


            <v-btn
                color="primary"
                elevation="2"
                raised
                @click='attemptRegistration()'
                >Register</v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>




<script>

export default {
    data(){
        return  {
            email:'',
            password:'',
            password_confirmation:''
        }
    },
    methods:{
        attemptRegistration(){
            this.$store.dispatch('authenticationData/registerUser',
                {axios:this.axios, data: {email:this.email, password:this.password,password_confirmation:this.password_confirmation}}
                
            )
            .then(() => {
                this.$router.push('/')
            }, error => {
                console.log(error)
            });
        }
    }
}
</script>
Footer
