<template>
    <v-container>
        <v-table>
            <thead>
                <tr>
                    <th class="text-left">
                        Name
                    </th>
                    <th class="text-left">
                        Description
                    </th>
                    <th class="text-left">
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in myProjects" :key="item">
                    <td>{{ item.name }}</td>
                    <td>{{ item.description }}</td>
                    <td>
                        <v-btn
                            @click="goToProjectStaking(item)"
                        >Manage Staking</v-btn>
                    
                        <v-btn
                            @click="goToStakingWidget(item)"
                        >Staking Widget</v-btn>
                        <v-btn
                            @click="openDialog(item)"
                        >Embed Widget</v-btn>
                    </td>
                </tr>
            </tbody>
        </v-table>

        <staking-widget-embed-dialog
            :project=dialogProject 
            :dialogOpen=dialogOpen
            @closeDialog="closeDialog()"
        />

    </v-container>
</template>


<script>
    export default {
        data(){
            return {
                dialogProject:null,
                dialogOpen:false
            }
        },
        methods:{
            goToStakingWidget(project){
                window.location.href = process.env.VUE_APP_STAKING_WIDGET_URL  + project.id;
            },
            goToProjectStaking(project){
                
                window.location.href = process.env.VUE_APP_STAKING_URL + 'project/' + project.id;
            },
            openDialog(project){
                this.dialogProject = project;
                this.dialogOpen = true
            },
            closeDialog(){
                this.dialogOpen=false
            }
        },
        computed: {
            myProjects() {
                return this.$store.getters['projectData/myProjects'];
            }
        }
    }
</script>

<style>
</style>