<template>
    <div class="text-center">

      <v-dialog
        v-model="dialog"
        width="50%"
      >
        <v-card>
          <v-card-text>
            {{ project.name }}
          </v-card-text>

            <pre>
                {{ getWidgetEmbedCode() }}
            </pre>

          <v-card-actions>
            <v-btn color="primary" block @click="closeDialog">Close Dialog</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>

<script>
export default {
    props:['project','dialogOpen'],
    data(){
        return {
            dialog:null
        }
    },
    methods:{
        getWidgetEmbedCode(){
            let base = process.env.VUE_APP_STAKING_WIDGET_EMBED_BASE;
            let projectID = this.project.id;
            console.log(projectID)
            // const textToEscape = `<script` + ` defer="defer" src="${base}/js/chunk-vendors.b0950f9b.js"></`+`script>
            //                         `+
            //                         `<scri`+`pt defer="defer" src="${base}/js/app.9e4f05ab.js" `+`></`+`script>
            //                         `+`
            //                         <lin`+`k href="${base}/css/chunk-vendors.76798738.css" rel="stylesheet">
                                    
            //                         <lin`+`k href="${base}/css/app.fe9d9a89.css" rel="stylesheet">
            //                         <div id="nxs-staking-staker" data-blockchain="Solana" data-project-id="${projectID}"></div>
            //                         `;
            const textToEscape =  `<scri`+`pt defer="defer" src="${base}/js/chunk-vendors.js" `+`></`+`script>
                                    <scri`+`pt defer="defer" src="${base}/js/app.js" `+`></`+`script>
                                    <div id="nxs-staking-staker" data-blockchain="Solana" data-project-id="${projectID}"></div>
            `
            
            
            return textToEscape
        },
        closeDialog(){
            this.$emit('closeDialog');
        }
    },
    watch:{
        dialogOpen(val){
            this.dialog = val
        }
    }
}
</script>

<style>

</style>