import axios, { CancelToken } from 'axios';

import helpers from './helpers';

const instance = axios.create({});

const source = CancelToken.source();

instance.defaults.headers.common['Accept'] = 'application/json';


instance.interceptors.request.use(config => {
  // If a request is already in progress, cancel the new request
  if (source.token) {
    source.cancel('Duplicate request detected.');
  }

  // Create a new cancel token for the current request
  source.token = axios.CancelToken.source().token;
  config.cancelToken = source.token;

  return config;
});

instance.interceptors.response.use(function (response) {
    source.token = null;
    return response;

  }, function (error) {
    
    if (error.response.status === 400) {
      if(error.response.data && error.response.data.message){
        helpers.createError(error.response.data.message);
      }
    } 

    source.token = null;
    console.log(error)
    return Promise.reject(error);
  });


export default instance;