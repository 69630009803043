import { createRouter
   , createWebHistory 
} from 'vue-router'
import store from '@/store/store'
import HomePage from '@/Pages/Home/HomePage';

import LoginPage from '@/Pages/Auth/LoginPage';
import RegistrationPage from '@/Pages/Auth/RegistrationPage';

import DiscordLogin from '@/Pages/Auth/DiscordLogin';

import ProjectsOverviewPage from '@/Pages/Projects/ProjectsOverviewPage';

const requireAuth = (to, from, next) => {
    const user = store.getters['authenticationData/currentUser'];
    if (!user) {
      next({ path: '/login' });
    } else {
      next();
    }
  };
const routes = [
        {
            path: '/',
            name: 'HomePage',
            component:HomePage,
            beforeEnter: requireAuth,
        },
        {
            path: '/login',
            name: 'LoginPage',
            component:LoginPage
        },
        {
            path: '/register',
            name: 'RegistrationPage',
            component:RegistrationPage
        },
        {
            path: '/discord-login',
            name: 'DiscordLogin',
            component:DiscordLogin,
        },
        {
          path: '/projects',
          name: 'ProjectsOverviewPage',
          component:ProjectsOverviewPage,
          beforeEnter: requireAuth,
      },
];


const router = createRouter({
    history: createWebHistory(),
    // mode:'hash',
    routes
  })  

export default router
  