<template>
  <v-btn
  @click="redirectTo(url)"
  >
    {{buttonText}}
  </v-btn>
</template>

<script>
export default {
    data(){
        return{
            buttonText:'Sign In With Discord',
            url:process.env.VUE_APP_DISCORD_LINK
        }
    },
    mounted(){
      this.getButtonText();
    },
    methods:{
      getButtonText(){
        if(this.currentUser){
          this.buttonText = "Link Discord Account"
        }
      },
      redirectTo(url){
        window.location.href = url;
      }
    },
    computed : {
      currentUser(){
        return this.$store.getters['authenticationData/currentUser']
      }
    },
    watch:{
      currentUser(){
        this.getButtonText();
      }
    }
}
</script>
