import { createStore } from 'vuex'


import {authenticationData} from './authentication/authenticationStoreData';
import {projectData} from './projects/projectStoreData'
import { errorData } from './errors/store';

export default createStore({
  
  modules: {
       authenticationData,
        projectData,
        errorData
     },
})