<template>
    <v-form @keyup.enter="attemptLogin">
        <v-row>
            <v-col cols="12">
                <v-text-field
                v-model="email"
                filled
                label="Your Email Address"
                clearable
                type="email"
                :rules="[
                    v => !!v || 'Email is required',
                    v => /.+@.+/.test(v) || 'Invalid Email address' 
                ]"
            ></v-text-field>

            <v-text-field
                v-model="password"
                filled
                label="Password"
                clearable
                type='password'
                :rules="[
                    v => !!v || 'Password Is Required',
                    v => v.length >= 6 || 'Passwod must be at least 6 characters long'
                ]"
            ></v-text-field>



            <v-btn
                color="primary"
                elevation="2"
                raised
                @click='attemptLogin()'
                >Login</v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>




<script>



export default {
    data(){
        return  {
            email:'',
            password:'',
            password_confirmation:''
        }
    },
    methods:{
        async attemptLogin(){

            this.$store.dispatch('authenticationData/loginUser',
                {data: {email:this.email, password:this.password}}
            )
            .then(() => {
                // console.log("yay?")
            }, error => {
                // console.log('nay??')
                console.log(error)
            });
        }
    },
    computed:{
        currentuser(){
            return this.$store.getters['authenticationData/currentUser'];
        }
    },
    watch:{
        currentuser(val){
            if(val){
                this.$router.push('/');
            }
        }
    }
}
</script>

