import 'vuetify/styles' // Global CSS has to be imported

import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router/router'
import store from './store/store.js'
import axios from '@/Services/axiosService'


axios.defaults.baseURL = process.env.VUE_APP_API_BASE;

// loadFonts()

const app = createApp(App).use(store).use(router).use(vuetify)


//Buttons
app.component('discord-login-button', require('./Components/Global/Buttons/DiscordLoginButton').default);

//Layout
app.component('error-snackbar', require('./Components/Layout/ErrorSnackbar').default);
app.component('side-menu', require('./Components/Layout/SideMenu').default);
//Auth
app.component('registration-form', require('./Components/Auth/RegistrationForm').default);
app.component('login-form', require('./Components/Auth/LoginForm').default);

//Projects
app.component('new-project-form', require('./Components/Projects/CreateNewProject').default);
app.component('user-project-list-table', require('./Components/Projects/UserProjectListTable').default);

//Staking
app.component('staking-widget-embed-dialog', require('./Components/Staking/StakingWidgetEmbedDialog').default);

const instance = axios.create({});
app.config.globalProperties.axios=instance

app.mount('#web-app')