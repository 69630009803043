import * as mutation from './authenticationMutationTypes'
import axios from '@/Services/axiosService'
import helpers from '@/Services/helpers';

const localStorageUserKey = process.env.VUE_APP_NAME + "_token";
const apiAddress = process.env.VUE_APP_API_BASE;

const authenticationData = {
    namespaced: true,
    state: ()=>({ 
        currentUser:false,
        token:false,
        discordToken:false,
        loginMethod:'',
        linkedAccounts:[]
    }),
    mutations: {
        [mutation.SET_CURRENT_USER] (state,user){
            state.currentUser = user;
          },
          [mutation.SET_APP_USER_TOKEN](state,data){
            state.token = data;
            localStorage.setItem(localStorageUserKey, state.token);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + data;
          },
          [mutation.RESET_ALL_AUTH_DATA](state){
            state.currentUser = null,
            state.token = null
            axios.defaults.headers.common['Authorization'] = 'Bearer ' ;
            localStorage.removeItem(localStorageUserKey);
          },
          [mutation.SET_DISCORD_TOKEN](state,token){
            state.discordToken = token;
          },
          [mutation.ADD_LINKED_ACCOUNT](state,data){
            let oldData = [...state.linkedAccounts];
            oldData.push(data)
            state.linkedAccounts = oldData;
          },
          [mutation.SET_LOGIN_METHOD](state,data){
            state.loginMethod = data;
          }
     },
    actions:{  
        async handleDiscordLogin({commit},discordToken){
            
            axios.post( apiAddress + '/user/discord',{discordToken}).then((resp)=>{
                let user = resp.data.user
                let token = resp.data.authorisation.token
                let linkedAccount = resp.data.linkedAccount
                commit(mutation.SET_CURRENT_USER,user);
                commit(mutation.SET_APP_USER_TOKEN,token);
                commit(mutation.SET_LOGIN_METHOD,"discord");
                commit(mutation.ADD_LINKED_ACCOUNT,linkedAccount);
            });
        },
        setDiscordToken({commit},token){
            commit(mutation.SET_DISCORD_TOKEN, token);
        },
        checkForExistingUser({commit}){
            let localStorageUser = localStorage.getItem(localStorageUserKey);
            if(localStorageUser){
                
                commit(mutation.SET_APP_USER_TOKEN,localStorageUser);
                return true;
            }else{
                // console.log("no local storage user found - need to login");
                return false;
            }
        },
        getCurrentUser({commit}){
            axios.get( apiAddress + '/user/confirm').then(resp =>{
                if(resp){ // TODO what do do here if no user is returned
                    if(resp.status == 200){
                        let user = resp.data.user
                        let token = resp.data.authorisation.token
                        commit(mutation.SET_CURRENT_USER,user);
                        commit(mutation.SET_APP_USER_TOKEN,token);
                    }else{
                        commit(mutation.RESET_ALL_AUTH_DATA);
                    }   
                }
                return resp;
            }) .catch(() => {
                // handle error
                console.log('resetting user data')
                commit(mutation.RESET_ALL_AUTH_DATA);
              });
        },
        registerUser({commit},object){
            let data = object.data;

            axios.post(apiAddress + '/user/register',data).then(resp =>{
                let user = resp.data.user
                let token = resp.data.authorisation.token
                commit(mutation.SET_CURRENT_USER,user);
                commit(mutation.SET_APP_USER_TOKEN,token);

                return resp;
            }).catch(function (error) {
                // handle error
                helpers.createError('There was an issue Registering. Check your details and try again.')
                console.log(error);
              });
        },
        logoutUser({commit}){
            axios.post(apiAddress + '/user/logout').then(()=> {
                
                commit(mutation.RESET_ALL_AUTH_DATA);
            });
        },
        loginUser({commit},object){
            let data = object.data;
            
            axios.post(apiAddress + '/user/login',data).then(resp =>{
                let user = resp.data.user
                let token = resp.data.authorisation.token
                commit(mutation.SET_CURRENT_USER,user);
                commit(mutation.SET_APP_USER_TOKEN,token);

                return resp;
            }) .catch(function (error) {
                // handle error
                helpers.createError('There was an issue logging in. Check your details and try again.')
                console.log(error);
              });
        },
     },
    getters:{  
        currentUser : state =>{
            return state.currentUser;
        },
        token : state =>{
            return state.token;
        },
        discordToken : state => {
            return state.discordToken;
        },
        loginMethod : state =>{
            return state.loginMethod;
        },
        linkedAccounts : state =>{
            return state.linkedAccounts
        }
    }
}

export {authenticationData};