<template>
  <v-container>
    <!-- IF THERE IS A USER LOGGED IN CHANGE BUTTON TO SAY "LINK MY DISCORD ACCOUNT". and confirm popup.-->
    I'm the discord login page
  </v-container>
</template>

<script>

export default {
    data(){
        return {
            
        }
    },
    async mounted(){


        let token ;
        try {
            token = this.$route.hash.split('access_token=')[1].split('&expires_')[0]//TODO use URL params if poss
        } catch (error) {
            console.log(error)
        }

        if(token){

            await this.$store.dispatch('authenticationData/setDiscordToken',token);

            if(!this.user || !this.token){
                console.log('Offer user to sign in and link discord with their account. First Save the discord token locally.')
                return false;
            }
            
        }
    },
    computed:{
        user(){
            return this.$store.getters['authenticationData/currentUser'];
        },
        token(){
            return this.$store.getters['authenticationData/token'];
        },
        discordToken(){
            return this.$store.getters['authenticationData/discordToken'];
        },
    },
    watch:{
        async discordToken(val){
            this.$store.dispatch('authenticationData/handleDiscordLogin',val);
            this.$router.push('/')
        }
    }
}
</script>
