// const localStorageUserKey = process.env.VUE_APP_LOCAL_STORAGE_KEY;
import store from '@/store/store';

const helpers = {
  createError(message, color = 'red'){
    store.dispatch('errorData/addError',{
        message,
        color
    }) 
    
  },
    isTokenFormattedAddress(address){
        return address.match(/^[123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{44}$/)
    },
 
    truncate(str,size) {
        const firstThree = str.substring(0, size);
        const lastThree = str.substring(str.length - size);
        return firstThree.concat('...', lastThree);
    },
      copyValueToClipboard(val) {
        navigator.clipboard.writeText(val).then(function() {
            // console.log('Text copied to clipboard ' + val);
          }, function(err) {
            console.error('Failed to copy text: ', err);
          });
      },
      searchOnSolscan(address, params={}){
        let url = 'https://solscan.io/';
        
        if(params.type){
            url += params.type + '/'
        }else{
            url += 'account/'
        }

        url += address;

        if(params.cluster){
            url += "?cluster=" + params.cluster
        }else{
            url += "?cluster=devnet"
        }

        window.open(url, '_blank');
      },
      addAlert(message){
        store.dispatch('alertData/add',message).then(error => {console.log(error)});
      },
      getUserAvatarImage(){
        let avatarImage = 'https://randomuser.me/api/portraits/women/85.jpg';
        let currentUser = store.getters['authenticationData/currentUser'];
        let loginMethod = store.getters['authenticationData/loginMethod'];
        let linkedAccounts = store.getters['authenticationData/linkedAccounts'];

        if(currentUser){
        //  let linkedAccountImage;
          linkedAccounts.forEach(linkedAccount => {
            if(linkedAccount.service == loginMethod || loginMethod == ''){
              let metaData = JSON.parse(linkedAccount.service_meta);
              switch (linkedAccount.service) {
                case 'discord':
                  avatarImage = `https://cdn.discordapp.com/avatars/` + linkedAccount.service_system_id+ `/`+metaData.avatar+`.webp`;
                  break;
              
                default:
                  break;
              }
            }
          });

        }
        return avatarImage;
      }

}

export default helpers;