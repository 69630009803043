
import * as mutation from './projectMutationTypes'
import axios from '@/Services/axiosService'
import helpers from '@/Services/helpers';
const apiAddress = process.env.VUE_APP_API_BASE;

const projectData = {
    namespaced: true,
    state: ()=>({ 
        myProjects:null,
        currentProject:null
    }),
    mutations: {
        [mutation.SET_CURRENT_PROJECT] (state,data){
            let theData = JSON.parse(JSON.stringify(data))
            state.currentProject = theData;
          },
          [mutation.SET_ALL_PROJECTS] (state,data){
            let theData = JSON.parse(JSON.stringify(data))
            state.myProjects = theData;
          },
          [mutation.ADD_PROJECT] (state,data){
            let theData = JSON.parse(JSON.stringify(data))
            state.myProjects.push(theData);
          },
          [mutation.RESET_PROJECTS] (state){
            state.myProjects = null;
          }
     },
    actions:{
        resetProjects({commit}){
            commit(mutation.RESET_PROJECTS);
        },
        createProject({commit},data){
            axios.post(apiAddress + '/projects',data).then(resp =>{
                commit(mutation.SET_CURRENT_PROJECT,resp.data);
                commit(mutation.ADD_PROJECT,resp.data);
                return resp;
            }).catch(function (error) {
                // handle error
                helpers.createError('There was an error creating the project')
                console.log(error);
              });
        },
        getMyProjects({commit}){
            axios.get(apiAddress + '/projects').then(resp=>{
                commit(mutation.SET_ALL_PROJECTS,resp.data);
                return resp;
            }).catch(function (error) {
                // handle error
                helpers.createError('There was an error retrieving your projects')
                console.log(error);
              });
        },
        setCurrentProject({commit},data){
            commit(
                mutation.SET_CURRENT_PROJECT,
                data
            );   
        }
     },
    getters:{  
        myProjects : state =>{
            return state.myProjects;
        },
        currentProject : state =>{
            return state.currentProject;
        },
    }
}

export {projectData};