<template>
    <v-form @keyup.enter="createProject">
        <v-row>
            <v-col cols="12">
                <v-text-field
                v-model="name"
                filled
                label="Project Name"
                clearable
                type="text"
                :rules="[
                        v => !!v || 'Project Name is required',
                        v => v.length >= 3 || 'Project Name must be at least 3 characters long'
                    ]"
                ></v-text-field>

                <v-textarea
                name="input-7-1"
                filled
                label="Description"
                auto-grow
                v-model="description"
                :rules="[
                        v => !!v || 'Project Description is required',
                        v => v.length >= 3 || 'Project Description must be at least 3 characters long'
                    ]"
                ></v-textarea>


                <v-btn
                color="primary"
                elevation="2"
                raised
                @click='createProject()'
                >Create Project</v-btn>

                <v-btn
                    color="primary"   
                    @click="$emit('closeDialog')"
                >
                Cancel
                </v-btn>
            </v-col>
        </v-row>
   </v-form>
</template>

<script>
export default {
   data(){
       return{
           name:'',
           description:''

       }
   },
   methods:{
       createProject(){
        this.$emit('closeDialog')   
           this.$store.dispatch('projectData/createProject',{name:this.name, description:this.description})
           .then(() => {
            
           }, error => {
            this.$emit('closeDialog')
               console.log(error)
           });
       }
   }
}
</script>
