<template>
   
        <v-navigation-drawer
          
          
        >
          <v-list
            v-if="user"
          >
            <v-list-item
           
              :prepend-avatar=avatarImage
              
              :subtitle="user.email"
            ></v-list-item>
            <v-list-item
            @click="logout"
            >
                Logout
            </v-list-item>
          </v-list>
  
          <v-divider></v-divider>
  
          <v-list
            v-if="!user"
          >
            <v-list-item
            @click="goToLogin"
            >
                Login
            </v-list-item>
            <v-list-item
            @click="goToRegister"
            >
                Register
            </v-list-item>
          </v-list>
         
          <v-divider></v-divider>
          
     
          <v-list
            v-if="myProjects"
          >
          <v-list-item
            @click="$router.push('/projects')"
          >
            <h3>Projects ({{ myProjects.length }})</h3>
          </v-list-item>
            
            <!-- <v-list-item 
                v-for="(item, i) in myProjects"
                :key="i"
            >
            {{ item.name }}
        </v-list-item> -->
      </v-list>
        </v-navigation-drawer>
  
      
  </template>
  <script>
    export default {
      data(){
        return{
          avatarImage:''
        }
      },
        methods:{
          goHome(){
            this.$router.push('/')
          },
            logout(){
                this.$store.dispatch('authenticationData/logoutUser').then(() => {
                  this.$router.push('/')
                });
            },
            goToLogin(){
                this.$router.push('/login')
            },
            goToRegister(){
                this.$router.push('/register')
            }
        },
        computed:{
            linkedAccounts(){
              return this.$store.getters['authenticationData/linkedAccounts'];
            },
            loginMethod(){
              return this.$store.getters['authenticationData/loginMethod'];
            },
            user(){
                return this.$store.getters['authenticationData/currentUser'];
            },
            token(){
                return this.$store.getters['authenticationData/token'];
            },
            myProjects(){
              return this.$store.getters['projectData/myProjects'];
            }
        },
        watch:{
          loginMethod(){
            this.linkedAccounts.forEach(linkedAccount => {
              if(linkedAccount.service == this.loginMethod){
                if(this.loginMethod == 'discord'){
                  let metaData = JSON.parse(linkedAccount.service_meta);
                  let imageURL = `https://cdn.discordapp.com/avatars/` + linkedAccount.service_system_id+ `/`+metaData.avatar+`.webp`
                  this.avatarImage = imageURL;
                }
              }
            });
          },
          user(val){
            if(val){
              this.$store.dispatch('projectData/getMyProjects').then(() => {});
            }else{
              this.$store.dispatch('projectData/resetProjects').then(() => {});
              this.$router.push('/login');
            }
          } 
        }
    }
  </script>